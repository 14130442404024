
/**
 * Dropzone.vue is used only in ImageUploader.vue
 * image uploader is divided in two components for readability
 */
export default {
    name: "Dropzone",
    /**
     * cancel: file selection is canceled
     * select-files: file is selected (Array<File>)
     * highlighted: v-model - shows if dropzone is highlighted or not (Boolean)
     */
    emits: ["cancel", "select-files", "highlighted"],
    components: {
        Button: () => import("@/components/base/Button"),
    },
    props: {
        /** Show cancel button */
        showCancelBtn: {
            type: Boolean,
            default: false,
        },
        /** Avatar mode */
        avatar: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isHighlighted: false,
            isDraggingOver: false,
            id: null,
        }
    },
    watch: {
        isHighlighted(value) {
            this.$emit("highlighted", value)
        },
    },
    mounted() {
        this.id = this.$getUID()
        this.$nextTick(() => {
            this.initDropzoneListeners()
        })
    },
    beforeDestroy() {
        this.$emit("highlighted", false)
        this.removeDropzoneListeners()
    },
    methods: {
        preventDefaults(e) {
            e.preventDefault()
            e.stopPropagation()
        },
        unhighlight(dropzone) {
            this.isDraggingOver = false
            setTimeout(() => {
                if (!this.isDraggingOver) {
                    this.isHighlighted = false
                    dropzone.classList.remove("shake")
                }
            }, 200)
        },
        highlight(dropzone) {
            this.isDraggingOver = true
            setTimeout(() => {
                if (this.isDraggingOver) {
                    this.isHighlighted = true
                    dropzone.classList.add("shake")
                }
            }, 200)
        },
        handleDrop(e) {
            const dataTransfer = e.dataTransfer
            const files = dataTransfer.files
            if (files.length) {
                this.isHighlighted = false
                this.$emit("select-files", files)
            }
        },
        onFileInputChange() {
            const files = document.getElementById(`input${this.id}`).files
            this.$emit("select-files", files)
        },
        onCancel() {
            this.$emit("cancel")
        },
        initDropzoneListeners() {
            // Upload Button
            const input = document.getElementById(`input${this.id}`)
            if (!input) return
            input.addEventListener("change", this.onFileInputChange, false)

            // Drag & Drop
            const dropzone = document.getElementById(`dropzone${this.id}`)
            // prevent defaults
            ;["dragenter", "dragover", "dragleave", "drop"].forEach(
                (eventName) => {
                    dropzone.addEventListener(
                        eventName,
                        this.preventDefaults,
                        false
                    )
                }
            )
            // highlight
            ;["dragenter", "dragover"].forEach((eventName) => {
                dropzone.addEventListener(
                    eventName,
                    () => this.highlight(dropzone),
                    false
                )
            })

            // unhighlight
            ;["dragleave", "drop"].forEach((eventName) => {
                dropzone.addEventListener(
                    eventName,
                    () => this.unhighlight(dropzone),
                    false
                )
            })
            // handleDrop
            dropzone.addEventListener("drop", this.handleDrop, false)
        },
        removeDropzoneListeners() {
            const input = document.getElementById(`input${this.id}`)
            const dropzone = document.getElementById(`dropzone${this.id}`)
            if (!input) return
            input.removeEventListener("change", this.onFileInputChange, false)
            if (!dropzone) return
            ;["dragenter", "dragover", "dragleave", "drop"].forEach(
                (eventName) => {
                    dropzone.removeEventListener(
                        eventName,
                        this.preventDefaults,
                        false
                    )
                }
            )
            // highlight
            ;["dragenter", "dragover"].forEach((eventName) => {
                dropzone.removeEventListener(
                    eventName,
                    () => this.highlight(dropzone),
                    false
                )
            })
            // unhighlight
            ;["dragleave", "drop"].forEach((eventName) => {
                dropzone.removeEventListener(
                    eventName,
                    () => this.unhighlight(dropzone),
                    false
                )
            })
            // handleDrop
            dropzone.removeEventListener("drop", this.handleDrop, false)
        },
    },
}
