/** Images */
export const JPEG = "image/jpeg"
export const PNG = "image/png"
export const SVG_XML = "image/svg+xml"
export const SVG = "image/svg"
export const GRIF = "image/gif"
export const HEIC = "image/heic"
export const HEIF = "image/heif"
// export const WEBP = "image/webp" // currently not supported

/** Videos */
export const MP4 = "video/mp4"
export const MOV = "video/mov"
export const WMV = "video/wmv"
export const AVI = "video/avi"
export const FLV = "video/flv"
export const QUICKTIME = "video/quicktime"

/** Files */
export const PDF = "application/pdf"
export const DOC = "application/msword"
export const DOCX =
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
export const XLS = "application/vnd.ms-excel"
export const XLSX =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

export const IMAGE_TYPES = Object.freeze([
    JPEG,
    PNG,
    SVG_XML,
    SVG,
    GRIF,
    HEIC,
    HEIF,
])
export const VIDEO_TYPES = Object.freeze([MP4, MOV, WMV, AVI, FLV, QUICKTIME])
export const MEDIA_TYPES = Object.freeze([...IMAGE_TYPES, ...VIDEO_TYPES])
export const FILE_TYPES = Object.freeze([PDF, DOC, DOCX, XLS, XLSX])
