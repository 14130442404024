const imageAPI = {
    methods: {
        getImage(id) {
            this.imageAPIStatus = "pending"
            return this.$axios
                .get(`/api/images/${id}`)
                .then((response) => {
                    if (response.status === 200) {
                        this.imageAPIStatus = "success"
                        return response.data
                    }
                    this.imageAPIStatus = "warning"
                })
                .catch((error) => {
                    this.imageAPIStatus = "error"
                    this.imageAPIlastError = error
                })
        },
        uploadImage(image) {
            this.imageAPIStatus = "pending"
            return this.$axios
                .post("/api/images", image, {
                    onUploadProgress: (progressEvent) =>
                        (this.imageApiPercentage = Number(
                            (
                                (100 * progressEvent.loaded) /
                                progressEvent.total
                            ).toFixed(0)
                        )),
                })
                .then((response) => {
                    if (response.status === 201) {
                        this.imageAPIStatus = "success"
                    } else {
                        this.imageAPIStatus = "warning"
                    }
                    return response.data.data
                })
                .catch((error) => {
                    this.imageAPIStatus = "error"
                    this.imageAPIlastError = error
                })
                .finally(() => {
                    this.imageApiPercentage = 0
                })
        },
        updateImage(image, id) {
            this.imageAPIStatus = "pending"
            return this.$axios
                .put(`/api/images/${id}`, image, {
                    onUploadProgress: (progressEvent) =>
                        (this.imageApiPercentage = (
                            (100 * progressEvent.loaded) /
                            progressEvent.total
                        ).toFixed(0)),
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.imageAPIStatus = "success"
                    } else {
                        this.imageAPIStatus = "warning"
                    }
                    return response.data.data
                })
                .catch((error) => {
                    this.imageAPIStatus = "error"
                    this.imageAPIlastError = error
                })
                .finally(() => {
                    this.imageApiPercentage = 0
                })
        },
        deleteImage(id) {
            this.imageAPIStatus = "pending"
            return this.$axios
                .delete(`/api/images/${id}`)
                .then((response) => {
                    if (response.status === 204) {
                        this.imageAPIStatus = "success"
                        return response.data
                    }
                    this.imageAPIStatus = "warning"
                })
                .catch((error) => {
                    this.imageAPIStatus = "error"
                    this.imageAPIlastError = error
                })
        },
    },
    data() {
        return {
            imageAPIStatus: "unsent",
            imageAPIlastError: "",
            imageApiPercentage: 0,
        }
    },
}
export default imageAPI
