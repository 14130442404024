
import { Cropper } from "vue-advanced-cropper"
import "vue-advanced-cropper/dist/style.css"
import ProgressCircular from "@/components/base/ProgressCircular"
import Button from "@/components/base/Button"
import Dropzone from "@/components/base/input/LegacyDropzone"
import imageAPI from "@/mixins/imageAPI"

import { mdiPencil, mdiClose } from "@mdi/js"
import { IMAGE_TYPES } from "@/enums/fileTypes"

/**
 * Should work with SSR now too. Please keep an eye on SSR errors when including this component
 */
export default {
    name: "ImageUploader",
    components: {
        ProgressCircular,
        Dropzone,
        Button,
        Cropper,
    },
    mixins: [imageAPI],
    /**
     * change: uploaded image (image Object) - used for v-model,
     * delete: emitted if delete button is pressed - the reference to the current image has to be removed
     */
    emit: ["change", "delete", "uploading", "preview"],
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        /** Avatar mode */
        avatar: {
            type: Boolean,
            default: false,
        },
        /** Editable mode */
        editable: {
            type: Boolean,
            default: false,
        },
        /** Editable mode */
        showEditButton: {
            type: Boolean,
            default: false,
        },
        /** Makes the image being uploaded as private image */
        private: {
            type: Boolean,
            default: false,
        },
        /** Enables (and enforces) image cropping */
        cropping: {
            type: Boolean,
            default: true,
        },
        /** removes the delete option (image can only be replaced with another) */
        hideDeleteBtn: {
            type: Boolean,
            default: false,
        },
        /** Aspect Ratio is always 1.0 in Avatar mode */
        aspectRatio: {
            type: Number,
            default: 1,
        },
        /** Max height of the component (pixels) */
        maxHeight: {
            default: 600,
            type: Number,
        },
        /** Size of image in Avatar mode (pixels) */
        avatarSize: {
            type: Number,
            default: 500,
        },
        /**
         * image object
         * {
         *   id: Number
         *   copyright: String
         *   description: String
         *   file_name: String
         *   file_path: String
         * }
         */
        value: {
            type: Object,
        },
        /** Restricts the croppable area. Expecting
         * "stecil" -> Selected area only within image
         * "none" -> Overflow with white background possible */
        restriction: {
            type: String,
            default: "stencil",
        },
        /** Array of accepted image types */
        accept: {
            type: Array,
            default: () => IMAGE_TYPES,
        },
        /** Max size of an raw image (bytes) */
        maxSize: {
            type: Number,
            default: 15e6, // 15MB
        },
        /** Max size of uploaded image - enables compression if necessary */
        maxUploadSize: {
            type: Number,
            default: 7e6, // 7MB
        },
        /** description field for the new image */
        description: {
            type: String,
            default: "",
        },
        /** copyright field for the new image */
        copyright: {
            type: String,
            default: "",
        },
        /** Specific placeholder to be shown instead of a basic one if image in null */
        placeholder: {
            type: String,
            default: null,
        },
    },
    icons: {
        mdiPencil,
        mdiClose,
    },
    data() {
        return {
            imageID: Math.random().toString(36).substr(2, 12),
            imageWidth: 5000,
            states: {
                previewing: false,
                editing: false,
                selecting: false,
                cropping: false,
                loading: true,
            },
            croppedImage: null,
            isDropzoneHighlighted: false,
            showUploadingError: false,
            uploadingError: null,
        }
    },
    computed: {
        isCurrentImage() {
            return !!this.image && !!this.image.id
        },
        image: {
            get() {
                return this.value
            },
            set(image) {
                this.switchState("loading")
                this.$nextTick(async () => {
                    let newImage = {}
                    if (!image?.id && this.isCurrentImage) {
                        this.$emit("delete")
                    }
                    if (image) {
                        this.$emit("preview", { local: image })
                        newImage = (await this.onImageUpload(image)) || {}
                    }
                    if (newImage && Object.keys(newImage).length !== 0) {
                        this.$emit("change", newImage)
                    }
                    this.resetState()
                })
            },
        },
        displayedImage() {
            if (this.isDropzoneHighlighted || this.states.cropping) return null
            if (!this.image && this.placeholder) {
                return this.placeholder
            }
            return this.$getImage({
                image: this.image,
                preferedSize: "0",
            })
        },
        lazyImage() {
            if (this.isDropzoneHighlighted || this.states.cropping) return null
            if (!this.image && this.placeholder) {
                return { src: this.placeholder }
            }
            return this.$getImage({
                image: this.image,
                preferedSize: "3",
            })
        },
        imageClasses() {
            return {
                "image-blurred-bright": this.states.loading,
                "image-blurred-dark": this.states.selecting,
                "image-blurred": this.states.editing,
                "circle-image": this.avatar,
                "rounded-3": !this.avatar,
                "background-grey": this.states.selecting,
                "white-background": this.restriction === "none",
            }
        },
        internalMaxHeight() {
            return this.avatar
                ? Math.min(this.imageWidth, this.avatarSize)
                : this.maxHeight
        },
        internalWidth() {
            return this.avatar ? this.avatarSize : null
        },
        internalAspectRatio() {
            return this.avatar ? 1 : this.aspectRatio
        },
        stencilProps() {
            return {
                handlers: {},
                movable: false,
                resizable: false,
                scalable: false,
                aspectRatio: this.internalAspectRatio,
            }
        },
    },
    watch: {
        editable() {
            this.resetState()
        },
        showEditButton() {
            this.resetState()
        },
    },
    mounted() {
        this.onResize()
    },
    created() {
        this.resetState()
    },
    methods: {
        onResize() {
            if (!document) return
            const element = document.getElementById(this.imageID)
            if (!element) return
            const width = element.getBoundingClientRect().width
            // smart resizing on small screens
            if (width > 0) this.imageWidth = width
        },
        resetState() {
            this.$nextTick(() => {
                let newState = "previewing"
                if (this.editable && !this.showEditButton) {
                    if (this.isCurrentImage) newState = "editing"
                    else newState = "selecting"
                    this.switchState("previewing")
                }
                this.switchState(newState)
            })
        },
        switchState(newState) {
            for (let state in this.states) {
                this.states[state] = newState === state
            }
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = (error) => reject(error)
            })
        },
        cropImage() {
            const el = this.$refs.cropper
            if (el) {
                let { canvas } = this.$refs.cropper.getResult()
                if (!canvas) return
                const imageSize = canvas.height * canvas.width
                if (imageSize > this.maxUploadSize) {
                    canvas = this.compress(canvas)
                }
                this.image = canvas.toDataURL()
            }
        },
        compress(canvas) {
            const resizedCanvas = document.createElement("canvas")
            const resizedContext = resizedCanvas.getContext("2d")
            const imageSize = canvas.height * canvas.width
            const factor = this.maxUploadSize / imageSize
            resizedCanvas.height = canvas.height * Math.sqrt(factor)
            resizedCanvas.width = canvas.width * Math.sqrt(factor)
            resizedContext.drawImage(
                canvas,
                0,
                0,
                resizedCanvas.width,
                resizedCanvas.height
            )
            return resizedCanvas
        },
        async handleFiles(files) {
            for (let i = 0, len = files.length; i < len; i++) {
                if (this.validateImage(files[i])) {
                    this.switchState("loading")
                    try {
                        let img = files[i]
                        if (
                            img.type === "image/heic" ||
                            img.type === "image/heif"
                        )
                            img = await this.convertImage(img)
                        this.croppedImage = URL.createObjectURL(img)
                        // if image is valid, proceed to the cropping step
                        if (this.cropping) {
                            this.switchState("cropping")
                        } else {
                            this.switchState("previewing")
                            this.image = await this.getBase64(img)
                        }
                    } catch (e) {
                        console.error(e)
                        this.switchState("selecting")
                    }
                    return
                }
            }
        },
        validateImage(image) {
            // check the type
            if (this.accept.indexOf(image.type) === -1) {
                this.showErrorSnackbar("Ungültiger Dateityp")
                return false
            }
            // check the size
            if (image.size > this.maxSize) {
                this.showErrorSnackbar(
                    `Datei zu groß. Limit: ${this.maxSize * 1e-6}MB`
                )
                return false
            }
            return true
        },
        showErrorSnackbar(message) {
            this.showUploadingError = false
            this.$nextTick(() => {
                this.uploadingError = message
                this.showUploadingError = true
            })
        },
        async onImageUpload(newImage) {
            this.$emit("uploading", true)
            const response = await this.uploadImage({
                image: newImage,
                description: this.description,
                copyright: this.copyright,
                public: !this.private,
            })
            response.local = newImage
            this.$emit("uploading", false)
            if (this.imageAPIStatus === "success") return response
            else {
                this.showErrorSnackbar(this.imageAPIlastError)
                return false
            }
        },
        /** Public: Finished any unfinished editing/cropping state */
        validate(force = false) {
            if (this.states.cropping) {
                if (force) {
                    this.cropImage()
                }
                return false
            }
            return true
        },
        async convertImage(image) {
            const heic2any = await import("heic2any")
            return await heic2any.default({ blob: image })
        },
    },
}
